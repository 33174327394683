<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer9.9/top.jpg"
    />

    <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
      />
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <img
        class="two"
        v-if="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
      />
      <img
        class="three"
        v-if="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
      />
      <template>
        <van-field
          v-model="form.phone_code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          :class="['input-code', { 'two-cell': num == 2 }]"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif"
        class="submit"
        @click="onSubmit"
      />

      <img
        class="four"
        v-if="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif"
      />
    </div>
    <div class="info">
      <div class="title">>>产品说明<<</div>
      <div class="cont">
       资费内容</br>
1、限浙江电信指定用户办理，时长型套餐、达量降速的畅享套餐(含畅享流量包)用户不支持订购该流量包，具体能否办理以页面验证为准。办理后12个月内，线上不支持叠加办理其他5G惠民流量包。</br>
2、9.9元/月，含5GB国内通用流量，订购当月费用及流量按天折算（例：用户于3月30日订购该5G惠民流量包，则用户3月可使用流量为包内流量/31天*2天）。</br>
3、订购立即生效，次月自动续订，退订次月生效，有效期内不支持重复订购。</br>
4、流量使用说明：</br>
（1）流量优先于套餐内流量使用，主副卡不共享，副卡可单独订购，流量仅限当月使用，不结转至次月；</br>
（2）流量适用于中国电信移动通信网络，仅限中国内地（不含港澳台和国际）使用，不含WLAN（Wi-Fi）上网；</br>
（3）产品办理号卡可享5G网速。5G网速需具备5G网络及5G终端，并满足归属地运营商规则的其他使用条件。非5G终端办理5G惠民流量包只能享受流量，不能享受5G网速。</br>
5、订购该业务需承诺在网12个月。</br>
6、如需退订，或有更多需求，请联系在线客服或者拨打4001189599处理，或前往当地营业厅办理，可通过中国电信APP-附近营业厅入口查询就近营业厅，也可通过拨打【代理商联系方式】咨询办理。</br>
7.客服热线：4001189599</br>
      </div>
    </div>
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer9.9/qdbs.png"
    />
     <!-- 二确 -->
    <BaseDialogConfirm
     :width="706"
      :height="695"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer9.9/dialog.png"
      :visibility.sync="visibility"
      closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_close.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_querendinggou.png"
    />
  </div>
</template>

<script>
import { sendSMS, buy } from "@/api/qiuMoFusion/index";
import { generateSign, JuDuoDuo } from "@/utils/common";
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  components: {
    BaseDialogConfirm,
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        phone_number: "",
        phone_code: "",
        response_order_id: "",
      },
      num: 1,
      visibility: false,
    };
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      JuDuoDuo("100023", phone, "9.9元5G惠民流量包");
    };
  },
  created() {
    const { phone = "" } = this.$route.query;
    this.form = {
      ...this.form,
      phone_number: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  beforeDestroy() {
    // 销毁时移除事件监听，防止影响其他页面
    window.onpopstate = null;
  },
  watch: {
    form: {
      handler(value) {
        const { phone_number = "", phone_code } = value;
        if (phone_number.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && phone_code.length == 0) {
          this.num = 2;
        } else if (phone_code.length < 6) {
          this.num = 3;
        } else if (phone_code.length == 6 && phone_number.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number = "" } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.yzm_loading = true;
      try {
        const res = await sendSMS({
          product_id: "21701001",
          phone_number,
          default_app_name: "无",
          default_package_name: "无",
          app_name: navigator.userAgent,
          auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
        });

        if (res.errorCode === "000") {
          this.form = {
            ...this.form,
            response_order_id: res.response_order_id,
          };
          // 设置倒计时秒
          this.sendAuthCode = false;
          this.auth_time = 120;
          let auth_timetimer = setInterval(() => {
            this.auth_time--;
            if (this.auth_time <= 0) {
              this.sendAuthCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        } else {
          this.$dialog.alert({ message: res.errorMsg });
          JuDuoDuo("100023", phone_number, "9.9元5G惠民流量包");
        }
      } finally {
        this.yzm_loading = false;
      }
    },
    onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone_number) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.phone_code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.phone_code)) {
        this.$toast.fail("验证码错误");
      } else {
        this.visibility = true;
      }
    },
    async handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const { response_order_id = "" } = this.form;
      const res = await buy({
        ...this.form,
        response_order_id,
        auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
      });
      this.visibility = false;
      this.$toast({
        duration: 8000,
        message: res.errorMsg,
      });
      if (res.errorCode === "000") {
        JuDuoDuo("100023", this.form.phone_number, "9.9元5G惠民流量包");
      } else {
        JuDuoDuo("100023", this.form.phone_number, "9.9元5G惠民流量包");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #e03323;
  .img {
    width: 100%;
  }

  .form {
    position: relative;

    padding: 90px 0 60px;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 156px;
      right: 40px;
      top: 158px;
    }

    .three {
      width: 456px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 310px;
      transform: translate(200px, -76px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #ff7900;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #ff7900;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 674px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(0.9);
        }
      }
    }
  }
  .info {
    color: #fff;
    padding: 20px 0 100px;
    .title {
      font-size: 32px;
      text-align: center;
      font-weight: 600;
    }

    .cont {
      font-size: 26px;
      line-height: 41px;
      padding: 20px 32px 0;
      white-space: pre-wrap;
    }
  }

  ::v-deep .dialog-confirm {
    .footer {
      padding-top: 570px;
      align-items: center;

      .btn-close {
        width: 180px;
      }

      .btn-submit {
        height: 72px;
        width: 215px;
      }
    }
  }
}
</style>